import { Fragment } from "react";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
// import Instructor from "../component/section/instructor";
// import Skill from "../component/section/skill";
// import Student from "../component/section/student";

const subTitle = "ABOUT E-QURAN COACH";
const title = "Why You Should Join E-Quran Coach.com?";
const desc =
"E QURAN COACH  is a spectacular platform of Online Quran Teaching which enables New Muslims and Children all over the world to read Quran with Tajweed from home.";

const year = "";
const expareance = "";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Free demo:",
    desc: "An introductory course regarding the principles involved in reading of Quran.",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Makeup class:",
    desc: "Lectures on the basic Ideology of Islam and the matters concerning daily life.",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Daily reminders:",
    desc: "Male and female coaches: You can choose a coach of your preferred gender.",
  },
];

const AboutPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"ABOUT E-QURAN COACH"} curPage={"About"} />
      <div className="about-section style-3 padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
            <div className="col">
              <div className="about-left">
                <div className="about-thumb">
                  <img src="assets/images/pageheader/5.png" alt="about" />
                </div>
                {/* <div className="abs-thumb">
                  <img src="assets/images/about/02.jpg" alt="about" />
                </div> */}
                {/* <div className="about-left-content">
                  <h3>{year}</h3>
                  <p>{expareance}</p>
                </div> */}
              </div>
            </div>
            <div className="col">
              <div className="about-right">
                <div className="section-header">
                  <span className="subtitle">{subTitle}</span>
                  <h2 className="title">{title}</h2>
                  <p>{desc}</p>
                </div>
                <div className="section-wrapper">
                  <ul className="lab-ul">
                    {aboutList.map((val, i) => (
                      <li key={i}>
                        <div className="sr-left">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div>
                        <div className="sr-right">
                          <h5>{val.title}</h5>
                          <p>{val.desc}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Student />
       <Skill />
      <Instructor />
     
      <AchievementTwo /> */}
      <Blog />
     
      {/* <Footer /> */}
    </Fragment>
  );
};

export default AboutPage;
