import { Fragment } from "react";
// import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import Archive from "../component/sidebar/archive";
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import Instagram from "../component/sidebar/instagram";
// import PopularPost from "../component/sidebar/popular-post";
// import PostCategory from "../component/sidebar/post-category";
// import Respond from "../component/sidebar/respond";
// import Search from "../component/sidebar/search";
// import Tags from "../component/sidebar/tags";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const BlogSingle = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        title={"Why Quran Education is Important?"}
        curPage={"Blog Detais"}
      />
      <div className="blog-section blog-single padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <article>
                <div className="section-wrapper">
                  <div className="row row-cols-1 justify-content-center g-4">
                    <div className="col">
                      <div className="post-item style-2">
                        <div className="post-inner">
                          <div className="post-thumb">
                            <img
                              src="assets/images/blog/why-quran-education-is-important/01.jpeg"
                              alt="blog thumb rajibraj91"
                              className="w-100"
                            />
                          </div>
                          <div className="post-content">
                            <h2>Why Quran Education is Important?</h2>
                            <div className="meta-post">
                              <ul className="lab-ul">
                                <li>
                                  <a href="#">
                                    <i className="icofont-calendar"></i>October 18,2023
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="icofont-ui-user"></i>E-Quran Coach
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <p>
                            Quran is a complete code of life and it is our religious and moral duty to get maximum benefit from it in our lives. Our main responsibility towards this Holy book is to read it correctly, understand it and spread its message among others.
                            Everyone can learn and teach Quran in his own way. The main concern should be not letting its spirit fade away. It can only be done by proper teaching with phonics and blending of sounds. For this reason, E QURAN COACH (EQC) has worked on developing a structured curriculum for Quranic education.

                            </p>

                            <blockquote>
                              <p>
                               
High frequency Vocabulary method is to teach most frequently used words first.
Teach similar & root words together through basic
Grammar and linguistic laws.
{" "}
                              </p>
                              {/* <cite>
                              <a href="https://aamax.co/" target="_blank">...AAMAX</a>

                              </cite> */}
                            </blockquote>

                            <p>
                            “Phonics of alphabet is focused instead of conventional sequence and pronunciation.
Quran Education for Schools is developed focusing the following characteristics

Shortest duration course to understand most of the Qur’an
100% Qur’anic Arabic covered with Tajweed
Curriculum is designed for KG-Matric classes
READ QUR’AN (Junior Level): We start educating primary classes i.e. KG to 5. In classes KG to 5 the whole Nazirah Qur’an will be completed by reading in classes while applying all the rules of Tajweed.

                            </p>

                            {/* <img
                              src="assets/images/blog/single/02.jpeg"
                              alt="rajibraj91"
                            /> */}

                            <p>
                            <strong>READ QUR’AN (Senior Level):</strong>

UNDERSTAND QUR’AN:
 For Secondary classes, we have designed our course to teach high frequency words with Grammar and TPI, so in first 3 classes i.e. VI, VII & VIII the student will learn and understand 85% of the Qur’anic words with Namaz, Duas and two Paras with understanding. In classes IX till XII, the Student will go through the whole Qur’an with understanding and 4 aspects in each Aayat.

                            </p>

                            {/* <div className="video-thumb">
                              <img
                                src="assets/images/blog/single/03.jpeg"
                                alt="video"
                              />
                              <a
                                href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                className="video-button popup"
                                target="_blank"
                              >
                                <i className="icofont-ui-play"></i>
                              </a>
                            </div> */}

                            <p>
                            <strong>TPI Method (Total Physical Interaction):</strong>

A research-based technique of Total Physical Interaction (TPI) for teaching Quran with actions and expressions.

                            </p>

                            <div className="tags-section">
                              {/* <ul className="tags lab-ul">
                                <li>
                                  <a href="#">Agency</a>
                                </li>
                                <li>
                                  <a href="#">Business</a>
                                </li>
                                <li>
                                  <a href="#">Personal</a>
                                </li>
                              </ul> */}
                              {/* <ul className="lab-ul social-icons">
                                {socialList.map((val, i) => (
                                  <li key={i}>
                                    <a
                                      href={val.link}
                                      className={val.className}
                                    >
                                      <i className={val.iconName}></i>
                                    </a>
                                  </li>
                                ))}
                              </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="navigations-part">
                        {/* <div className="left">
                          <a href="#" className="prev">
                            <i className="icofont-double-left"></i>Previous
                            Article
                          </a>
                          <a href="#" className="title">
                            Evisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div>
                        <div className="right">
                          <a href="#" className="prev">
                            Next Article<i className="icofont-double-right"></i>
                          </a>
                          <a href="#" className="title">
                            Qvisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div> */}
                      </div>
                      {/* <Author />
                      {/* <Comment /> */}
                      {/* <Respond />  */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-12">
              <aside>
                {/* <Search />
                <PostCategory />
                <PopularPost />
                <Archive />
                <Instagram />
                <Tags /> */}
              </aside>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default BlogSingle;
