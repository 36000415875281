import { useState, Fragment } from "react";
import emailjs from "emailjs-com";

// import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";
import { message } from "antd";

const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle =
  "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Send our Message";

const contactList = [
  {
    imgUrl: "assets/images/icon/01.png",
    imgAlt: "contact icon",
    title: "Office Address",
    desc: "Chak # 17, LAHORE PUNJAB PAKISTAN",
  },
  {
    imgUrl: "assets/images/icon/02.png",
    imgAlt: "contact icon",
    title: "Phone number",
    desc: " +92 3060004006",
  },
  {
    imgUrl: "assets/images/icon/03.png",
    imgAlt: "contact icon",
    title: "Send email",
    desc: "equrancoachofficial@gmail.com",
  },
  {
    imgUrl: "assets/images/icon/04.png",
    imgAlt: "contact icon",
    title: "Our website",
    desc: "equrancoach.com",
  },
];

const ContactPage = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    number: "",
    demoDateTime: "",
    numOfStudents: "",
    age: "",
    level: "",
    location: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3eb28g6",
        "template_ramvjkg",
        e.target,
        "HgbMHNGXCONdZ_02l"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormValues({
            name: "",
            email: "",
            number: "",
            demoDateTime: "",
            numOfStudents: "",
            age: "",
            level: "",
            location: "",
            message: "",
          });
          message.success("Email sent successfully!");
        },
        (error) => {
          console.log(error.text);
          message.error("Failed to send email. Please try again.");
        }
      );
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Book For Free Demo"} curPage={"Free Demo"} />

      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form className="contact-form" onSubmit={sendEmail}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  placeholder="Student Name *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  placeholder="Student Email *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="number"
                  value={formValues.number}
                  onChange={handleChange}
                  placeholder="Contact Number *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="demoDateTime"
                  value={formValues.demoDateTime}
                  onChange={handleChange}
                  placeholder="Demo date and time *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="numOfStudents"
                  value={formValues.numOfStudents}
                  onChange={handleChange}
                  placeholder="No Of Students *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="age"
                  value={formValues.age}
                  onChange={handleChange}
                  placeholder="Age *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="level"
                  value={formValues.level}
                  onChange={handleChange}
                  placeholder="Level *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="location"
                  value={formValues.location}
                  onChange={handleChange}
                  placeholder="Country, State *"
                />
              </div>
              <div className="form-group w-100">
                <textarea
                  rows="8"
                  name="message"
                  value={formValues.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="form-group w-100 text-center">
                <button className="lab-btn" type="submit">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="map-address-section padding-tb section-bg">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{subTitle}</span>
            <h2 className="title">{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-xl-4 col-lg-5 col-12">
                <div className="contact-wrapper">
                  {contactList.map((val, i) => (
                    <div className="contact-item" key={i}>
                      <div className="contact-thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="contact-content">
                        <h6 className="title">{val.title}</h6>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-12">
                <GoogleMap />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default ContactPage;
