
import { Link } from "react-router-dom";


const subTitle = "FORM OUR BLOG POSTS";
const title = "More Articles From Resource Library";


const blogList = [
    {
        imgUrl: 'assets/images/blog/01.jpeg',
        imgAlt: 'blog thumb rajibraj91 rajibraj',
        title: 'Why Quran Education is Important?',
        url: '/why-quran-education-is-important',    
        author: 'E-Quran Coach',
        date: 'October 18,2023',
        desc: 'Quran is a complete code of life and it is our religious and moral duty For Quran Of  Life.',
        btnText: 'Read more',
        commentCount: '3',
    },
    {
        imgUrl: 'assets/images/blog/02.jpeg',
        imgAlt: 'blog thumb rajibraj91 rajibraj',
        title: 'How To Become A Muslim.',
        url: '/how-to-become-a-muslim',
        author: 'E-Quran Coach',
        date: 'October 18,2023',
        desc: ' Islam The Fastest Growing Religion. Islam is often referred to as the fastest-growing religion. Islam attracts new followers conversion.',
        btnText: 'Read more',
        commentCount: '4',
    },
    {
        imgUrl: 'assets/images/blog/03.jpg',
        imgAlt: 'blog thumb rajibraj91 rajibraj',
        title: 'Aspects Considered For Each Aayat.',
        url: '/aspects-considered-for-each-aayat',
        author: 'E-Quran Coach',
        date: 'October 18,2023',
        desc: 'TAJWEED POEMS: To make better understanding of each word’s pronunciation and memorizing.',
        btnText: 'Read more',
        commentCount: '6',
    },
]

const Blog = () => {
    return (
        <div className="blog-section padding-tb section-bg">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                        {blogList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="post-item">
                                    <div className="post-inner">
                                        <div className="post-thumb">
                                            <Link to={val.url}><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                        </div>
                                        <div className="post-content">
                                            <Link to={val.url}><h4>{val.title}</h4></Link>
                                            <div className="meta-post">
                                                <ul className="lab-ul">
                                                    <li><i className="icofont-ui-user"></i>{val.author}</li>
                                                    <li><i className="icofont-calendar"></i>{val.date}</li>
                                                </ul>
                                            </div>
                                            <p>{val.desc}</p>
                                        </div>
                                        <div className="post-footer">
                                            <div className="pf-left">
                                                <Link to={val.url} className="lab-btn-text">{val.btnText} <i className="icofont-external-link"></i></Link>
                                            </div>
                                            {/* <div className="pf-right">
                                                <i className="icofont-comment"></i>
                                                <span className="comment-count">{val.commentCount}</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Blog;