


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16253.934753288262!2d74.06249763754934!3d31.082792985495807!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39184fae6981bb29%3A0x71f50c3c861890ea!2sJamia%20Taleem%20Ul%20Quran%20Wal%20Hadith!5e0!3m2!1sen!2s!4v1697860748448!5m2!1sen!2s "></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;