import React from "react";
import Header from "./header";
import Footer from "./footer";
import WhatsAppButton from "./WhatsAppButton";

function SiteDefaultLayout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <WhatsAppButton />
    </>
  );
}

export default SiteDefaultLayout;
