import { Link } from "react-router-dom";

const subTitle = "E-Quran Coach";
const title = (
  <h2 className="title">
    <span className="d-lg-block"></span>  Your Quranic Journey Starts Today {" "}
    <span className="d-lg-block"></span>
  </h2>
);


const catagoryList = [
  {
    name: "Figma",
    link: "#",
  },
  {
    name: "Adobe XD",
    link: "#",
  },
  {
    name: "illustration",
    link: "#",
  },
  {
    name: "Photoshop",
    link: "#",
  },
];

const shapeList = [
  {
    name: "Free Demo",
    link: "/free-demo",
    className: "ccl-shape shape-1",
  },
  {
    name: "Makeup class",
    link: "#",
    className: "ccl-shape shape-2",
  },
  {
    name: "Daily Reminders",
    link: "#",
    className: "ccl-shape shape-3",
  },
  {
    name: "One to one classes",
    link: "#",
    className: "ccl-shape shape-4",
  },
  {
    name: "Highly Qualified Teachers",
    link: "#",
    className: "ccl-shape shape-5",
  },
];

const Banner = () => {
  return (
    <section className="banner-section">
      <div className="container">
        <div className="section-wrapper">
          <div className="row align-items-center">
            <div className="col-xxl-5 col-xl-6 col-lg-10">
              <div className="banner-content">
                <h6 className="subtitle text-uppercase fw-medium">
                  {subTitle}
                </h6>
                {title}
                <p className="desc"> I hope this helps! Please Click The <strong><Link to='/free-demo'>Free Demo</Link></strong></p>
                {/* <form action="/">
                                    <div className="banner-icon">
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="Keywords of your course" />
                                    <button type="submit">Search Course</button>
                                </form>
                                <div className="banner-catagory d-flex flex-wrap">
                                    <p>Most Popular : </p>
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {catagoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul>
                                </div> */}
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <div className="banner-thumb">
                <img src="assets/images/banner/04.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-shapes"></div>
      <div className="cbs-content-list d-none">
        <ul className="lab-ul">
          {shapeList.map((val, i) => (
            <li className={val.className} key={i}>
              <a href={val.link}>{val.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Banner;
