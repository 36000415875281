import { Component, Fragment } from "react";
// import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import CourseSideCetagory from "../component/sidebar/course-cetagory";
// import CourseSideDetail from "../component/sidebar/course-detail";
// import Respond from "../component/sidebar/respond";

const CourseSix = () => {
  return (
    <Fragment>
      <Header />
      <PageHeaderTwo />
      <div className="course-single-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h2>Tarjamatul Quran</h2>
                      <p>
                      This online Quranic grammar course is designed for students of all ages who want to learn the basic rules of quranic grammar as they apply to the Quran. The course will be taught by one of the best Quranic grammar coaches in the world, and will cover all aspects of Quranic grammar, including the different parts of speech, the rules of syntax, and the different types of Quranic sentences. Students will also learn how to apply the rules of Quranic grammar to their Quran recitation and interpretation.
</p>
                      <p><b>Course procedures</b></p>
                      <p>Each class will begin with a review of the previous lesson. Students will then be taught new material on Quran memorization techniques and strategies. Students will have the opportunity to practice their memorization skills during class, and they will also be given assignments to complete at home.
                      </p>
                      <h4>The course will cover the following topics:</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>The pronunciation of Arabic letters and words

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>The rules of elongation and shortening

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>The different types of Quranic pauses

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>How to apply the rules of Tajweed to Quran recitation

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Advanced Tajweed concepts and techniques

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Course delivery
                        </li>
                      </ul>
                      <p>
                      Students should come to class prepared to recite the Quran.
Students should have a computer or mobile device with a camera and microphone in order to participate in the course.
Students are expected to complete all assignments on time.
Students are encouraged to ask questions during class.
Additional tips for success

                      </p>
                      <p>
                      Set realistic goals for yourself. Don't try to learn too much too quickly.
Be consistent with your studies. Set aside a specific time each day to practice Tajweed.
Record yourself reciting the Quran and listen back to your recording. This is a great way to identify areas where you need to improve.
Find a Tajweed study partner or group. Studying with others can help you stay motivated and accountable.
Get feedback from a qualified Tajweed teacher. This is the best way to ensure that you are learning Tajweed correctly.


                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="course-video">
                  <div className="course-video-title">
                    <h4>Course Content</h4>
                  </div>
                  <div className="course-video-content">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <div className="accordion-header" id="accordion01">
                          <button
                            className="d-flex flex-wrap justify-content-between"
                            data-bs-toggle="collapse"
                            data-bs-target="#videolist1"
                            aria-expanded="true"
                            aria-controls="videolist1"
                          >
                            <span>1.Introduction</span>{" "}
                            <span>5lessons, 17:37</span>{" "}
                          </button>
                        </div>
                        <div
                          id="videolist1"
                          className="accordion-collapse collapse show"
                          aria-labelledby="accordion01"
                          data-bs-parent="#accordionExample"
                        >
                          <ul className="lab-ul video-item-list">
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.1 Welcome to the course 02:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.2 How to set up your photoshop workspace 08:33
                                minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.3 Essential Photoshop Tools 03:38 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.4 Finding inspiration 02:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.5 Choosing Your Format 03:48 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="accordion02">
                          <button
                            className="d-flex flex-wrap justify-content-between"
                            data-bs-toggle="collapse"
                            data-bs-target="#videolist2"
                            aria-expanded="true"
                            aria-controls="videolist2"
                          >
                            {" "}
                            <span>
                              2.How to Create Mixed Media Art in Adobe Photoshop
                            </span>{" "}
                            <span>5 lessons, 52:15</span>{" "}
                          </button>
                        </div>
                        <div
                          id="videolist2"
                          className="accordion-collapse collapse"
                          aria-labelledby="accordion02"
                          data-bs-parent="#accordionExample"
                        >
                          <ul className="lab-ul video-item-list">
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.1 Using Adjustment Layers 06:20 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.2 Building the composition 07:33 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.3 Photoshop Lighting effects 06:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.4 Digital Painting using photoshop brushes
                                08:34 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.5 Finalizing the details 10:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <Author /> */}
                {/* <Comment /> */}
                {/* <Respond /> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                {/* <CourseSideDetail /> */}
                {/* <CourseSideCetagory /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default CourseSix;
