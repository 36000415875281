import { BrowserRouter, Routes, Route } from "react-router-dom";
import "swiper/css";

// import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import BlogPage from "./page/blog";
import BlogPageTwo from "./page/blog-2";
import BlogPageThree from "./page/blog-3";
import BlogSingle from "./page/why-quran-education-is-important";
import ContactPage from "./page/free-demo";
import CoursePage from "./page/course";
import CourseSingle from "./page/nurani-qaida";
import Home from "./page/home";
import SiteDefaultLayout from "./component/layout";
import BlogSecond from "./page/how-to-become-a-muslim";
import BlogThird from "./page/aspects-considered-for-each-aayat";
import CourseTwo from "./page/nazra-quran";
import CourseThree from "./page/hifz-quran";
import CourseFour from "./page/tafseer-e-quran";
import CourseFive from "./page/tajweed-course";
import CourseSix from "./page/tarjamatul-quran";

function App() {
  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <SiteDefaultLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="course" element={<CoursePage />} />
          <Route path="nurani-qaida" element={<CourseSingle />} />
          <Route path="nazra-quran" element={<CourseTwo />} />
          <Route path="hifz-quran" element={<CourseThree />} />
          <Route path="tafseer-e-quran" element={<CourseFour />} />
          <Route path="tajweed-course" element={<CourseFive />} />
          <Route path="tarjamatul-quran" element={<CourseSix />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-2" element={<BlogPageTwo />} />
          <Route path="blog-3" element={<BlogPageThree />} />
          <Route
            path="why-quran-education-is-important"
            element={<BlogSingle />}
          />
          <Route path="how-to-become-a-muslim" element={<BlogSecond />} />
          <Route
            path="aspects-considered-for-each-aayat"
            element={<BlogThird />}
          />
          <Route path="about" element={<AboutPage />} />
          <Route path="free-demo" element={<ContactPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </SiteDefaultLayout>
    </BrowserRouter>
  );
}

export default App;
