const subTitle = "ABOUT E-QURAN COACH";
const title = "Why You Should Join E-Quran Coach.com?";
const desc =
  "E QURAN COACH  is a spectacular platform of Online Quran Teaching which enables New Muslims and Children all over the world to read Quran with Tajweed from home. We welcome all students interested in Quran learning, The program includes Quran Reading (Nazra Quran), Nurani Qaida, Memorizing Quran, Translation and Prayers Duas with the 6 Kalimas You are just one click away learning from highly trained, qualified, dedicated male and female Quran teachers we have. Our Quran tutors are available to teach Quran 24/7. For free trial click here, In sha Allah you and your kids can start Quran learning immediately.";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Free demo",
    desc: "An introductory course regarding the principles involved in reading of Quran.",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Daily reminders",
    desc: "Lectures on the basic Ideology of Islam and the matters concerning daily life.",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Makeup class",
    desc: "Male and female coaches: You can choose a coach of your preferred gender.",
  },
];

const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
          <div className="col">
            <div className="about-right padding-tb">
              <div className="section-header">
                <span className="subtitle">{subTitle}</span>
                <h2 className="title">{title}</h2>
                <p>{desc}</p>
              </div>
              <div className="section-wrapper">
                <ul className="lab-ul">
                  {aboutList.map((val, i) => (
                    <li key={i}>
                      <div className="sr-left">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="sr-right">
                        <h5>{val.title}</h5>
                        <p>{val.desc}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="about-left">
              <div className="about-thumb">
                <img src="assets/images/about/4.webp" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
