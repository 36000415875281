import { Fragment } from "react";
import { Link } from "react-router-dom";
// import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import Pagination from "../component/sidebar/pagination";

const blogList = [
  {
    imgUrl: "assets/images/blog/why-quran-education-is-important/01.jpeg",
    imgAlt: "Blog Thumb",
    title: "Why Quran Education is Important?.",
    url: '/why-quran-education-is-important',  
    desc:'Quran is a complete code of life and it is our religious and moral duty For Quran Of  Life.',
    // commentCount: "3",
    btnText: "Read More",
    metaList: [
      {
        iconName: "icofont-ui-user",
        text: "E-Quran Coach",
      },
      {
        iconName: "icofont-calendar",
        text: "October 18,2023",
      },
    ],
  },
  {
    imgUrl: "assets/images/blog/02.jpeg",
    imgAlt: "Blog Thumb",
    title: "How To Become A Muslim.",
    desc: 'Islam The Fastest Growing Religion. Islam is often referred to as the fastest-growing religion. Islam attracts new followers conversion.',
    url: '/how-to-become-a-muslim',  
    // commentCount: "3",
    btnText: "Read More",
    metaList: [
      {
        iconName: "icofont-ui-user",
        text:'E-Quran Coach',
      },
      {
        iconName: "icofont-calendar",
        text: "October 18,2023",
      },
    ],
  },
  {
    imgUrl: 'assets/images/blog/03.jpg',
    imgAlt: "Blog Thumb",
    title: "Aspects Considered For Each Aayat.",
    desc: 'TAJWEED POEMS: To make better understanding of each word’s pronunciation and memorizing.',
    url: '/aspects-considered-for-each-aayat',  
    // commentCount: "3",
    btnText: "Read More",
    metaList: [
      {
        iconName: "icofont-ui-user",
        text: 'E-Quran Coach',
      },
      {
        iconName: "icofont-calendar",
        text: "October 18,2023",
      },
    ],
  },
  // {
  //   imgUrl: "assets/images/blog/04.jpg",
  //   imgAlt: "Blog Thumb",
  //   title: "Strategic Social Media and of visual design.",
  //   desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
  //   url: '/blog-single',  
  //   commentCount: "3",
  //   btnText: "Read More",
  //   // metaList: [
  //   //   {
  //   //     iconName: "icofont-ui-user",
  //   //     text: "E-Quran Coach",
  //   //   },
  //   //   {
  //   //     iconName: "icofont-calendar",
  //   //     text: "October 18,2023",
  //   //   },
  //   // ],
  // },
  // {
  //   imgUrl: "assets/images/blog/05.jpg",
  //   imgAlt: "Blog Thumb",
  //   title: "Find the Right Path for your Group Course online.",
  //   desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
  //   url: '/blog-single',  
  //   commentCount: "3",
  //   btnText: "Read More",
  //   metaList: [
  //     {
  //       iconName: "icofont-ui-user",
  //       text: "E-Quran Coach",
  //     },
  //     {
  //       iconName: "icofont-calendar",
  //       text: "October 18,2023",
  //     },
  //   ],
  // },
  // {
  //   imgUrl: "assets/images/blog/06.jpg",
  //   imgAlt: "Blog Thumb",
  //   title: "Learn by doing with Real World Projects other countries.",
  //   desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
  //   url: '/blog-single',  
  //   commentCount: "3",
  //   btnText: "Read More",
  //   metaList: [
  //     {
  //       iconName: "icofont-ui-user",
  //       text: "E-Quran Coach",
  //     },
  //     {
  //       iconName: "icofont-calendar",
  //       text: "October 18,2023",
  //     },
  //   ],
  // },
  // {
  //   imgUrl: "assets/images/blog/07.jpg",
  //   imgAlt: "Blog Thumb",
  //   title: "The Importance Of Intrinsic for Students.",
  //   desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
  //   url: '/blog-single',  
  //   commentCount: "3",
  //   btnText: "Read More",
  //   metaList: [
  //     {
  //       iconName: "icofont-ui-user",
  //       text: "E-Quran Coach",
  //     },
  //     {
  //       iconName: "icofont-calendar",
  //       text: "October 18,2023",
  //     },
  //   ],
  // },
  // {
  //   imgUrl: "assets/images/blog/08.jpg",
  //   imgAlt: "Blog Thumb",
  //   title: "A Better Alternative To Grading Student Writing.",
  //   desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
  //   url: '/blog-single',  
  //   commentCount: "3",
  //   btnText: "Read More",
  //   metaList: [
  //     {
  //       iconName: "icofont-ui-user",
  //       text: "E-Quran Coach",
  //     },
  //     {
  //       iconName: "icofont-calendar",
  //       text: "October 18,2023",
  //     },
  //   ],
  // },
  // {
  //   imgUrl: "assets/images/blog/09.jpg",
  //   imgAlt: "Blog Thumb",
  //   title: "The Challenge Global Learning In Public Education.",
  //   desc: "Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe",
  //   url: '/blog-single',  
  //   commentCount: "3",
  //   btnText: "Read More",
  //   metaList: [
  //     {
  //       iconName: "icofont-ui-user",
  //       text: "E-Quran Coach",
  //     },
  //     {
  //       iconName: "icofont-calendar",
  //       text: "October 18,2023",
  //     },
  //   ],
  // },
];

const BlogPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Our Blog Posts"} curPage={"Blog"} />
      <div className="blog-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
              {blogList.map((val, i) => (
                <div className="col" key={i}>
                  <div className="post-item">
                    <div className="post-inner">
                      <div className="post-thumb">
                        <Link to={val.url}>
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </Link>
                      </div>
                      <div className="post-content">
                        <Link to={val.url}>
                          <h4>{val.title}</h4>
                        </Link>
                        <div className="meta-post">
                          <ul className="lab-ul">
                            {val.metaList.map((val, i) => (
                              <li key={i}>
                                <i className={val.iconName}></i>
                                {val.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <p>{val.desc}</p>
                      </div>
                      <div className="post-footer">
                        <div className="pf-left">
                          <Link to={val.url} className="lab-btn-text">
                            {val.btnText}{" "}
                            <i className="icofont-external-link"></i>
                          </Link>
                        </div>
                        {/* <div className="pf-right">
                          <i className="icofont-comment"></i>
                          <span className="comment-count">
                            {val.commentCount}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <Pagination /> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default BlogPage;
