import { Fragment } from "react";
// import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import Archive from "../component/sidebar/archive";
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import Instagram from "../component/sidebar/instagram";
// import PopularPost from "../component/sidebar/popular-post";
// import PostCategory from "../component/sidebar/post-category";
// import Respond from "../component/sidebar/respond";
// import Search from "../component/sidebar/search";
// import Tags from "../component/sidebar/tags";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const BlogThird = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        title={"Aspects Considered For Each Aayat"}
        curPage={"Blog Detais"}
      />
      <div className="blog-section blog-single padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <article>
                <div className="section-wrapper">
                  <div className="row row-cols-1 justify-content-center g-4">
                    <div className="col">
                      <div className="post-item style-2">
                        <div className="post-inner">
                          <div className="post-thumb">
                            <img
                              src="assets/images/blog/aspects-considered-for-each-aayat/01.jpg"
                              alt="blog thumb rajibraj91"
                              className="w-100"
                            />
                          </div>
                          <div className="post-content">
                            <h2>Aspects Considered For Each Aayat</h2>
                            <div className="meta-post">
                              <ul className="lab-ul">
                                <li>
                                  <a href="#">
                                    <i className="icofont-calendar"></i>October 18,2023
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="icofont-ui-user"></i>E-Quran Coach
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <p>
                            TAJWEED POEMS: To make better understanding of each word’s pronunciation and memorizing it in order of Makharij (point of articulation), we developed Tajweed poems in all regional languages of Pakistan, exhibiting TPI as well as a poem demonstrating Makharij with animation.

VIDEO TUTORIALS: Through video tutorials we target that segment of society which we cannot reach to physically. These tutorials aim to transfer comprehensive yet concise training through our experienced trainers. So as a part of the learning process, we made several live tutorials and short animated clips for the teachers and students.

                            </p>

                            <blockquote>
                              <p>
                               
                              UNDERSTAND QUR’AN (level II to V): This is 80 hours course which covers 100% words of the Holy Qur’an along with complete understanding of Holy Qur’an Through recitation of half PARA per session.

{" "}
                              </p>
                              {/* <cite>
                              <a href="https://aamax.co/" target="_blank">...AAMAX</a>
                              </cite> */}
                            </blockquote>

                            <p>
                            Teacher’s Manual: is designed to provide correct direction to the teachers with introductions, framework, activities, and other supplemental material to help lead the class effectively. We have included activities, discussion questions and key vocabulary at the end of each chapter for facilitation.


                            </p>

                            {/* <img
                                src="assets/images/blog/third/02.webp"
                              alt="rajibraj91"
                            /> */}

                            <p>
                            Model Paper Practices: We have introduced model papers practice system to learn things with focus. The research shows that the exhaustion before exams can be reduced by solving more and more model papers for better preparation. These papers help schools and teachers to devise exams strategy as per their need.


                            </p>

                            {/* <div className="video-thumb">
                              <img
                                 src="assets/images/blog/third/03.jpg"
                                alt="video"
                              />
                              <a
                                href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                className="video-button popup"
                                target="_blank"
                              >
                                <i className="icofont-ui-play"></i>
                              </a>
                            </div> */}

                            <p>
                            Prayers (Dua)
Self-assessing
Plan for implementation
Preaching to others


                            </p>

                            <div className="tags-section">
                              {/* <ul className="tags lab-ul">
                                <li>
                                  <a href="#">Agency</a>
                                </li>
                                <li>
                                  <a href="#">Business</a>
                                </li>
                                <li>
                                  <a href="#">Personal</a>
                                </li>
                              </ul> */}
                              {/* <ul className="lab-ul social-icons">
                                {socialList.map((val, i) => (
                                  <li key={i}>
                                    <a
                                      href={val.link}
                                      className={val.className}
                                    >
                                      <i className={val.iconName}></i>
                                    </a>
                                  </li>
                                ))}
                              </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="navigations-part">
                        {/* <div className="left">
                          <a href="#" className="prev">
                            <i className="icofont-double-left"></i>Previous
                            Article
                          </a>
                          <a href="#" className="title">
                            Evisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div>
                        <div className="right">
                          <a href="#" className="prev">
                            Next Article<i className="icofont-double-right"></i>
                          </a>
                          <a href="#" className="title">
                            Qvisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div> */}
                      </div>
                      {/* <Author />
                      <Comment /> */}
                      {/* <Respond /> */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-12">
              <aside>
                {/* <Search />
                <PostCategory />
                <PopularPost />
                <Archive />
                <Instagram />
                <Tags /> */}
              </aside>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default BlogThird;
