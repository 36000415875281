import React from "react";
import { Link } from "react-router-dom";

const newsTitle = "";
const socialTitle = "Our Courses";
const supportTitle = "Usefull Links";
const getInTouchTitle = "Get in Touch";

const siteList = [
  {
    text: "Quran is the Divine word and within it carries the secrets of both this world and the world hereafter. For a Muslim it is mandatory as well as a moral obligation to learn Quran so that he or she is able to understand the instructions of God and live life accordingly.",
    link: "/about",
  },
  {
    text: "",
    link: "#",
  },
  {
    text: "",
    link: "#",
  },
  {
    text: " ",
    link: "#",
  },
  {
    text: "",
    link: "#",
  },
];

const socialList = [
  {
    text: "Nurani Qaida",
    link: "/nurani-qaida",
  },
  {
    text: "Nazra Quran",
    link: "/nazra-quran",
  },
  {
    text: "Hifz Quran",
    link: "/hifz-quran",
  },
  {
    text: "Tajweed Course",
    link: "/tajweed-course",
  },
  {
    text: "Tarjamatul Quran",
    link: "/tarjamatul-quran",
  },
  {
    text: "Tafseer e Quran",
    link: "/tafseer-e-quran",
  },
];

const supportList = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Courses",
    link: "/nurani-qaida",
  },
  {
    text: "Blog",
    link: "/blog",
  },
  {
    text: "About",
    link: "/about",
  },
  {
    text: "Free Demo",
    link: "/free-demo",
  },
];

const getInTouchList = [
  {
    text: "Ph:+92 3060004006",
    link: "/free-demo",
  },
  {
    text: "Chak # 17, LAHORE PUNJAB PAKISTAN",
    link: "/free-demo",
  },
  {
    text: "equrancoachofficial@gmail.com",
    link: "/free-demo",
  },
];

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="news-footer-wrap">
      <div className="fs-shape">
        <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
        <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
      </div>

      <div className="news-letter">
        <div className="container">
          <div className="section-wrapper">
            <div className="news-title">
              <h3>{newsTitle}</h3>
            </div>
            <div className="news-form">
              <form action="/">
                {/* <div className="nf-list">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <input type="submit" name="submit" value="Subscribe Now" />
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-top padding-tb pt-0">
          <div className="container">
            <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>About</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {siteList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{socialTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {socialList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{supportTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {supportList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{getInTouchTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {getInTouchList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom style-2">
          <div className="container">
            <div className="section-wrapper">
              <p>
                &copy; 2022 - {currentYear}
                <Link to="/">eQuran Coach</Link>THIS SITE POERED BY <strong>ALKHADIM GROUP</strong> | Made with ❤️ by
                <a href="https://aamax.co/" target="_blank">
                  AAMAX
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
