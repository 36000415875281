import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const phoneNumber = "+92 3060004006";
const address = "Chak # 17, LAHORE PUNJAB PAKISTAN";

let socialList = [
  {
    iconName: "icofont-facebook",
    siteLink:
      "https://www.facebook.com/profile.php?id=100092358225885&mibextid=ZbWKwL",
  },

  {
    iconName: "icofont-twitter",
    siteLink: "https://x.com/EQuranCoah?t=vOTjnal1SQzK0b8F1lQxDQ&s=09",
  },
  {
    iconName: "icofont-whatsapp",
    siteLink: "https://wa.me/+92 3060004006",
  },
  {
    iconName: "icofont-facebook-messenger",
    siteLink: "https://m.me/equrancoach",
  },
  {
    iconName: "icofont-skype",
    siteLink: "skype:live:.cid.89637d200231f104?chat",
  },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p>Find us on : </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink}>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo/01.png" alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  {/* <li>
                    <a
                      href="/"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Home
                    </a>
                  </li> */}
                  <li className="menu-item-has-children">
                    <a
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Courses
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/nurani-qaida">Nurani Qaida</NavLink>
                      </li>
                      <li>
                        <NavLink to="/nazra-quran">Nazra Quran</NavLink>
                      </li>
                      <li>
                        <NavLink to="/hifz-quran">Hifz Quran</NavLink>
                      </li>
                      <li>
                        <NavLink to="/tajweed-course">Tajweed Course</NavLink>
                      </li>
                      <li>
                        <NavLink to="/tarjamatul-quran">Tarjamatul Quran</NavLink>
                      </li>
                      <li>
                        <NavLink to="/tafseer-e-quran">Tafseer e Quran</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink to="/blog">Blog</NavLink>
                  </li>
                  {/* <li>
                    <a
                      href="/blog"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Blog
                    </a> */}
                  {/* <ul className="lab-ul dropdown-menu"> */}

                  {/* <li>
                        <NavLink to="/blog-single">Blog Single</NavLink>
                      </li> */}
                  {/* </ul> */}
                  {/* </li> */}
                  {/* <li>
                    <a
                      href="/about"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      About
                    </a> */}
                  {/* <ul className="lab-ul dropdown-menu"> */}
                  {/* <li>
                        <NavLink to="/about">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/team">Team</NavLink>
                      </li>
                      <li>
                        <NavLink to="/instructor">Instructor</NavLink>
                      </li>
                      <li>
                        <NavLink to="/shop">Shop Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/shop-single">Shop Details Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/cart-page">Shop Cart Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-page">Search Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-none">Search None</NavLink>
                      </li>
                      <li>
                        <NavLink to="/404">404</NavLink>
                      </li> */}
                  {/* </ul> */}
                  {/* </li> */}
                  <li>
                    <NavLink to="/about">About</NavLink>
                  </li>
                  <li>
                    <NavLink to="/free-demo">Free Demo</NavLink>
                  </li>
                </ul>
              </div>

              {/* <Link to="/login" className="login">
                <i className="icofont-user"></i> <span>LOG IN</span>{" "}
              </Link>
              <Link to="/signup" className="signup">
                <i className="icofont-users"></i> <span>SIGN UP</span>{" "}
              </Link> */}

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
