
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const subTitle = "";
const title = "Students Community Feedback";


const studentList = [
    {
        imgUrl: 'assets/images/feedback/student/01.jpg',
        imgAlt: 'student rajibraj91 rajibraj',
        name: 'Hs sani ',
        degi: 'From Ellahabad',
        desc: 'By fostering a collaborative and engaging learning environment, e-Quran coaches can help their students to learn more effectively and to develop the skills they need to succeed in the 21st century.',
    },
    {
        imgUrl: 'assets/images/feedback/student/02.jpg',
        imgAlt: 'student rajibraj91 rajibraj',
        name: 'ZIA',
        degi: 'FROM  LAHORE  PAKISTAN',
        desc: 'I am writing to express my sincere gratitude for the excellent e-Quran coaching I have received. My teacher, is a knowledgeable, patient, and engaging teacher. They have a deep understanding of the Quran and its teachings, and they are able to explain complex concepts in a clear and concise manner. I have also appreciated the flexibility and convenience of the e-Quran Coach platform. The online learning environment is user-friendly and easy to navigate.  Overall, I have been extremely satisfied with my experience with e-Quran Coach. I would highly recommend this program to anyone who is looking for a high-quality e-Quran learning experience.Thank you for providing such a valuable served For Everyone One By One.',
    },
]


const Student = () => {
    return (
        <div className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                            <div className="sfl-thumb">
    <img src="assets/images/Learn Quran With Quranhost.jpg" alt="student feedback" />
</div>

                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                                </div>
                                                <div className="sftl-content">
                                                    <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            <div className="sft-right">
                                                <Rating />
                                            </div>
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;