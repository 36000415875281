import { Component, Fragment } from "react";
// import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import CourseSideCetagory from "../component/sidebar/course-cetagory";
// import CourseSideDetail from "../component/sidebar/course-detail";
// import Respond from "../component/sidebar/respond";

const CourseFour = () => {
  return (
    <Fragment>
      <Header />
      <PageHeaderTwo />
      <div className="course-single-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h2>Tafseer e Quran</h2>
                      <p>
                     
This online Tafseer course is designed for students of all ages who want to learn about the meaning and interpretation of the Quran. The course will cover the basic principles of Tafseer, as well as the different approaches to interpreting the Quran. Students will also learn how to apply the Quran to their daily lives.
</p>
                      <p><b>Course procedures</b></p>
                      <p>Each class will begin with a review of the previous lesson. Students will then be taught new material on Quran memorization techniques and strategies. Students will have the opportunity to practice their memorization skills during class, and they will also be given assignments to complete at home.
                      </p>
                      <h4>The course will cover the following topics:</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>The different parts of speech in Arabic

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>The rules of Arabic syntax

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>The different types of Quranic sentences

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>How to apply the rules of Quranic grammar to Quran recitation and interpretation

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Advanced Quranic grammar concepts and techniques
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Course delivery
                        </li>
                      </ul>
                      <p>
                      Students will be assessed on their understanding of the course material and their ability to apply the rules of Quranic grammar to their Quran recitation and interpretation. Students will be given a final exam at the end of the course.

                      </p>
                      <p>
                      Set realistic goals for yourself. Don't try to learn too much too quickly.
Be consistent with your studies. Set aside a specific time each day to study Quranic grammar.

                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="course-video">
                  <div className="course-video-title">
                    <h4>Course Content</h4>
                  </div>
                  <div className="course-video-content">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <div className="accordion-header" id="accordion01">
                          <button
                            className="d-flex flex-wrap justify-content-between"
                            data-bs-toggle="collapse"
                            data-bs-target="#videolist1"
                            aria-expanded="true"
                            aria-controls="videolist1"
                          >
                            <span>1.Introduction</span>{" "}
                            <span>5lessons, 17:37</span>{" "}
                          </button>
                        </div>
                        <div
                          id="videolist1"
                          className="accordion-collapse collapse show"
                          aria-labelledby="accordion01"
                          data-bs-parent="#accordionExample"
                        >
                          <ul className="lab-ul video-item-list">
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.1 Welcome to the course 02:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.2 How to set up your photoshop workspace 08:33
                                minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.3 Essential Photoshop Tools 03:38 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.4 Finding inspiration 02:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.5 Choosing Your Format 03:48 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="accordion02">
                          <button
                            className="d-flex flex-wrap justify-content-between"
                            data-bs-toggle="collapse"
                            data-bs-target="#videolist2"
                            aria-expanded="true"
                            aria-controls="videolist2"
                          >
                            {" "}
                            <span>
                              2.How to Create Mixed Media Art in Adobe Photoshop
                            </span>{" "}
                            <span>5 lessons, 52:15</span>{" "}
                          </button>
                        </div>
                        <div
                          id="videolist2"
                          className="accordion-collapse collapse"
                          aria-labelledby="accordion02"
                          data-bs-parent="#accordionExample"
                        >
                          <ul className="lab-ul video-item-list">
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.1 Using Adjustment Layers 06:20 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.2 Building the composition 07:33 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.3 Photoshop Lighting effects 06:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.4 Digital Painting using photoshop brushes
                                08:34 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.5 Finalizing the details 10:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <Author /> */}
                {/* <Comment /> */}
                {/* <Respond /> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                {/* <CourseSideDetail /> */}
                {/* <CourseSideCetagory /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default CourseFour;
