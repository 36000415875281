import { Fragment } from "react";
// import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import Archive from "../component/sidebar/archive";
// import Author from "../component/sidebar/author";
// import Comment from "../component/sidebar/comment";
// import Instagram from "../component/sidebar/instagram";
// import PopularPost from "../component/sidebar/popular-post";
// import PostCategory from "../component/sidebar/post-category";
// import Respond from "../component/sidebar/respond";
// import Search from "../component/sidebar/search";
// import Tags from "../component/sidebar/tags";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const BlogSecond = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        title={"How To Become A Muslim"}
        curPage={"Blog Detais"}
      />
      <div className="blog-section blog-single padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <article>
                <div className="section-wrapper">
                  <div className="row row-cols-1 justify-content-center g-4">
                    <div className="col">
                      <div className="post-item style-2">
                        <div className="post-inner">
                          <div className="post-thumb">
                            <img
                              src="assets/images/blog/how-to-become-a-muslim/01.jpeg"
                              alt="blog thumb rajibraj91"
                              className="w-100"
                            />
                          </div>
                          <div className="post-content">
                            <h2>How To Become A Muslim</h2>
                            <div className="meta-post">
                              <ul className="lab-ul">
                                <li>
                                  <a href="#">
                                    <i className="icofont-calendar"></i>October 18,2023
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="icofont-ui-user"></i>E-Quran Coach
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <p>
                           <strong>01.</strong> Islam – The Fastest Growing Religion. Islam is often referred to as the fastest-growing religion. Islam attracts new followers through conversion from various religions from all over the world. Various factors such as personal conviction, marriage, or exposure to Islamic teachings can lead to people adopting the religion.

                            </p>

                            <blockquote>
                              <p>
                               
                              ” Ashadu Anlla Ilaaha IalaAllahu , Wa Ashadu Inna Muhammadan Rasulullah .”
In Arabic:  أشهد أن لا إله إلا الله وأشهد أن محمدا رسول الله

{" "}
                              </p>
                              {/* <cite>
                              <a href="https://aamax.co/" target="_blank">...AAMAX</a>
                              </cite> */}
                            </blockquote>

                            <p>
                            <strong>02.</strong>  Who is a Muslim? Becoming a Muslim requires a very simple act, but the meaning behind it is very deep. You must believe that there is only one God, Allah, who created the entire universe, and that Muhammad (peace be upon him) is his final messenger on earth. Muslims think of themselves as members of one family called the Ummah. It’s a family of all Muslims, and not just of all Muslims around the world, but of all Muslims who ever existed.


                            </p>

                            {/* <img
                              src="assets/images/blog/second/02.webp"
                              alt="rajibraj91"
                            /> */}

                            <p>
                            <strong>03.</strong>First Thing to Do. Declaring the Shahada(ٱلشَّهَادَةُ) is the first step towards how to become a Muslim. If you recite the following Arabic lines, with total sincerity, in front of two witnesses or an Imam (Islamic religious leader), you have become a Muslim. After saying the Shahada, you should take a bath, it is a symbolic act that signifies the washing away of the past. The Shahada is a simple statement that affirms the core belief of Islam. It is as follows:

                            </p>

                            {/* <div className="video-thumb">
                              <img
                                src="assets/images/blog/second/03.webp"
                                alt="video"
                              />
                              <a
                                href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg"
                                className="video-button popup"
                                target="_blank"
                              >
                                <i className="icofont-ui-play"></i>
                              </a>
                            </div> */}

                            <p>
                            “I bear witness that there is no god but Allah, and I also bear witness that Muhammad is the messenger of Allah.”
By sincerely reciting and believing in the Shahada, a person formally enters the fold of Islam and becomes a Muslim. The declaration acknowledges the belief in the oneness of Allah and the prophethood of Muhammad.

                            </p>

                            <div className="tags-section">
                              {/* <ul className="tags lab-ul">
                                <li>
                                  <a href="#">Agency</a>
                                </li>
                                <li>
                                  <a href="#">Business</a>
                                </li>
                                <li>
                                  <a href="#">Personal</a>
                                </li>
                              </ul> */}
                              {/* <ul className="lab-ul social-icons">
                                {socialList.map((val, i) => (
                                  <li key={i}>
                                    <a
                                      href={val.link}
                                      className={val.className}
                                    >
                                      <i className={val.iconName}></i>
                                    </a>
                                  </li>
                                ))}
                              </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="navigations-part">
                        {/* <div className="left">
                          <a href="#" className="prev">
                            <i className="icofont-double-left"></i>Previous
                            Article
                          </a>
                          <a href="#" className="title">
                            Evisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div>
                        <div className="right">
                          <a href="#" className="prev">
                            Next Article<i className="icofont-double-right"></i>
                          </a>
                          <a href="#" className="title">
                            Qvisculate Parallel Processes via Technica Sound
                            Models Authoritative
                          </a>
                        </div> */}
                      </div>
                      {/* <Author />
                      <Comment /> */}
                      {/* <Respond /> */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-12">
              <aside>
                {/* <Search />
                <PostCategory />
                <PopularPost />
                <Archive />
                <Instagram />
                <Tags /> */}
              </aside>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default BlogSecond;
